:root {
  body.light-theme {
    --background-color: #ffffff;
    --border-color-primary: #d8d9dd;
    --input-bg-primary: #f6f7f9;
    --common-black-white: #000;
    --logo-text-color: #000;
    --font-color-primary: #14151c;
    --font-color-secondary: #011f79;
    --input-font-color: #001f79;
    --login-input-label-background-color: #ffffff;
    --regular-font-family: "Montserrat-Regular";
    --medium-font-family: "Montserrat-Medium";
    --input-label-font-color: rgb(140, 151, 178);
    --button-bg-secondary: #9c9c9c;
    --button-font-secondary: #ffffff;
    --button-border-secondary: #848484;
    --modal-background-color: #ffffff;
    --modal-header-text: #14151c;
    --modal-submit-btn: #ffffff;
    --scroll-header-text-color: #4b4b4d;
    --selection-bar-background-color: #ffffff;

    --homepage-title: #2c2c2c;
    --homepage-header-primary: #60c7d0;
    --homepage-header-secondary-text: #4f4f55;
    --homepage-header-secondary-name-text: #008ad3;
    --homepage-header-secondary-name-avatar: #6d6f77;
    --homepage-navbar-background-color: #ffffff;
    --homepage-navbar-hamburger-icon: #14151c;
    --homepage-card-background-color: #ffffff;
    --homepage-card-text-color: #313238;
    --homepage-card-border-color: #e0e0e0;
    --homepage-card-box-shadow-color: #0000001a;
    --homepage-content-container-color: #f0f1f4;
    --homepage-xs-more-options: #1c1d1f;

    --homepage-my-profile-background-color: #fff;
    --homepage-my-profile-text-color: #000;
    --homepage-my-profile-input-field-background-color: #9d9ea4;

    --custom-modal-background-color: #ffffff;
    --custom-modal-title-color: #27282a;

    --change-password-form-background-color: #ffffff;
    --change-password-form-input-field-color: #f6f7f9;
    --change-password-form-input-color: #001f79;
    --change-password-form-cancel-btn-color: #9c9c9c;
    --change-password-form-eye-icon-color: #000;

    --homepage-drawer-title: #14151c;
    --homepage-drawer-text-color: #14151c;

    //--homepage-popover-background-color: #f0f1f4;
    --homepage-popover-background-color: #fff;
    --homepage-popover-content-text-color: #3a4355;

    --sitespage-text-color: #3d3e44;
    --sitespage-search-icon-text-color: #828282;
    --sitespage-table-header-color: #ffffff;
    --sitespage-table-nth-row-text-color: #f0f1f4;
    --sitespage-table-nthplusone-row-text-color: #ffffff;
    --sitespage-search-bar-placeholder: #000;
    --sitespage-textbox-color: #fff;

    --common-input-field-background-color: #f0f4f4;
    --common-input-field-text-color: #001f79;
    --common-background-color: #ffffff;
    --common-text-color: #242c79;
    --common-table-top-radius: 8px;
    --common-divider-color: #0505050f;

    --common-dashboard-background-color: #F0F14;
    --common-dashboard-card-background-color: #ffffff;

    --common-dashboard-card-list-background-color: #ffffff;
    --common-dashboard-profile-card-bg-color: rgb(0 0 0 / 6%);

    --dashboard-hearbeat-loading-bg-color-primary: rgba(255, 255, 255, 1);
    --dashboard-hearbeat-loading-bg-color-secondary: rgba(255, 255, 255, 1);

    --info-button-icon: #686868;

    --country-dropdown-hover: #f1f1f1;
    --common-scrollbar-thumb: rgba(52, 53, 65, 1);

    --field-select-bar-bg:#cdcdcd;
    --column-select-bar-bg:#fff;
  }
}