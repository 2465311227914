:root {
  body.dark-theme {
    --background-color: #14151c;
    --border-color-primary: #23252d;
    --input-bg-primary: #090a0e;
    --common-black-white: #fff;
    --logo-text-color: #caccd1;
    --font-color-primary: #caccd1;
    --font-color-secondary: #ffffff;
    --input-font-color: #017a87;
    --login-input-label-background-color: #090a0e;
    --regular-font-family: "Montserrat-Regular";
    --medium-font-family: "Montserrat-Medium";
    --input-label-font-color: rgb(140, 151, 178);
    --scroll-header-text-color: #656972;
    --selection-bar-background-color: #090a0e;

    --button-bg-secondary: #20222a;
    --button-font-secondary: #787a84;
    --button-border-secondary: #008f9c;
    --modal-background-color: #15151d;
    --modal-header-text: #caccd1;
    --modal-submit-btn: #07070a;

    --homepage-title: #ffffff;
    --homepage-header-primary: #00a5b4 !important;
    --homepage-header-secondary-text: #c1c2c2;
    --homepage-header-secondary-name-text: #0179b8;
    --homepage-header-secondary-name-avatar: #0179b8;
    --homepage-navbar-background-color: #090b0a;
    --homepage-navbar-background-color: #090b0a;
    --homepage-navbar-hamburger-icon: #ffffff;
    --homepage-card-background-color: #27282a;
    --homepage-card-text-color: #ffffff;
    --homepage-card-border-color: #494949;
    --homepage-card-box-shadow-color: #0000004d;
    --homepage-content-container-color: #1c1d1f;
    --homepage-xs-more-options: #fff;

    --homepage-my-profile-background-color: #fff;
    --homepage-my-profile-text-color: #fff;
    --homepage-my-profile-input-field-background-color: #fff;

    --custom-modal-background-color: #27282a;
    --custom-modal-title-color: #ffffff;

    --change-password-form-background-color: #27282a;
    --change-password-form-input-field-color: #090a0e;
    --change-password-form-input-color: #017a87;
    --change-password-form-cancel-btn-color: #9c9c9c;
    --change-password-form-eye-icon-color: #9c9c9c;

    --homepage-drawer-title: #14151c;
    --homepage-drawer-text-color: #fff;

    --homepage-popover-background-color: #1c1d1f;
    --homepage-popover-content-text-color: #fff;

    --sitespage-text-color: #f1f1f1;
    --sitespage-search-icon-text-color: #ffffff;
    --sitespage-table-header-color: #1c1d1f;
    --sitespage-table-nth-row-text-color: #283142;
    --sitespage-table-nthplusone-row-text-color: #303f56;
    --sitespage-search-bar-placeholder: #598cb2;
    --sitespage-textbox-color: #090a0e;

    --common-input-field-background-color: #090a0e;
    --common-input-field-text-color: #017a87;
    --common-background-color: #1c1d1f;
    --common-text-color: #ffffff;

    --common-table-top-radius: "0px";
    --common-divider-color: #c1c1c1;

    --common-dashboard-background-color: #1c1d1f;
    --common-dashboard-card-background-color: #27282a;

    --common-dashboard-card-list-background-color: #292a2e;
    --common-dashboard-profile-card-bg-color: rgba(255, 255, 255, 0.06);

    --dashboard-hearbeat-loading-bg-color-primary: rgba(39, 40, 42, 1);
    --dashboard-hearbeat-loading-bg-color-secondary: rgba(0, 0, 0, 0);

    --info-button-icon: #fff;

    --country-dropdown-hover: #2d333d;
    --common-scrollbar-thumb: rgba(102, 101, 99, 1);

    --field-select-bar-bg:#283142;
    --column-select-bar-bg:#23252d;
  }
}